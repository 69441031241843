import React, { useEffect, useState } from 'react';
import './App.css';
import {GetSchedules, AddSchedule, RemoveSchedule} from './functions/API'
import Calendar from 'react-calendar'
import "./Calendar.css";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


function Revervation({removeScheduleAndReload, loadSchedule, schedule}) {
  const [pw, setPW] = useState(null);
  const deleteReservation = () => {
    removeScheduleAndReload(schedule.id, pw);
    loadSchedule();
  }

  return (
    <div className="reservation">
      <div className="line flexbtw">
        <div className="user"><div className={"dot-big "+schedule.user}/>{schedule.user}</div>
        <input className='resv-pw' onChange={(e) => {setPW(e.target.value)}} placeholder="비밀번호"></input>
      </div>
      {schedule.memo?
      <div className="line">
        <div className="memo">memo: {schedule.memo}</div>
      </div>:<></>}
      <div className="line flexbtw mrtop8">
        <div className="start-time">사용시간 {schedule.startTime}~{schedule.endTime}</div>
        <div className="delete-reservation" onClick={deleteReservation}>삭제하기</div>
      </div>
    </div>
  )
}

function App() {
  const [schedules, setSchedules] = React.useState([]);
  const [value, onChange] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showReserve, setShowReserv] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [user, setUser] = useState("");
  const [memo, setMemo] = useState("");
  const [password, setPassword] = useState("");
  const [reserveMode, setReserveMode] = useState("simple");
  const [selectedSimpleOption, setSelectedSimpleOption] = useState(null);
  const simpleReserveOption = ["종일","오전","오후","저녁"]

  const userList = ["은기", "현기", "아빠", "엄마", "지훈"]

  const onClickCalendar = (e) => {
    onChange(e);
    setSelectedDate(e);
    setShowReserv(true);
    initDates(e);
  }

  const initDates = (e) => {
    const now = new Date();
    const tempDate = e;
    tempDate.setHours(now.getHours());
    tempDate.setMinutes(now.getMinutes());
    tempDate.setSeconds(now.getSeconds());
    setStartDate(selectedDate);
    setEndDate(selectedDate);
  }

  useEffect(() => {
    if(endDate < moment(startDate).add(1, 'hour').toDate()) {
      setEndDate(moment(startDate).add(1, 'hour').toDate());
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const newDateStart = new Date(selectedDate);
    const newDateEnd = new Date(selectedDate);
    newDateStart.setMinutes(0);
    newDateEnd.setMinutes(0);
    switch (selectedSimpleOption){
      case "종일":
        newDateStart.setHours(8);
        setStartDate(newDateStart);
        newDateEnd.setHours(23);
        setEndDate(newDateEnd);
        break
      case "오전":
        newDateStart.setHours(8);
        setStartDate(newDateStart);
        newDateEnd.setHours(12);
        setEndDate(newDateEnd);
        break
      case "오후":
        newDateStart.setHours(12);
        setStartDate(newDateStart);
        newDateEnd.setHours(18);
        setEndDate(newDateEnd);
        break
      case "저녁":
        newDateStart.setHours(18);
        setStartDate(newDateStart);
        newDateEnd.setHours(23);
        setEndDate(newDateEnd);
        break
    }
  }, [selectedSimpleOption]);

  useEffect(() => {
    loadSchedule();
  },[])

  const loadSchedule = async () => {
    const new_schedules = await GetSchedules();
    setSchedules(new_schedules);
  };

  // 일정 추가 함수
  const addScheduleAndReload = async (newSchedule) => {
    try {
      setPassword("")
      setMemo("")
      setUser("")
      AddSchedule(newSchedule);
    } catch (error) {
      console.error(error);
    }
  };

  // 일정 삭제 함수
  const removeScheduleAndReload = async (id, pw) => {
    try {
      const isRemoved = await RemoveSchedule(id, pw);
      loadSchedule();
      if(isRemoved) {
        alert("정상적으로 삭제 되었습니다.")
      }else{
        alert("비밀번호를 확인해주세요.")
      }

    } catch (error) {
      console.error(error);
    }
  };

  const closeBox = (e) => {
    if (e.target.className == "reserve-wrap" || e.target.className == "close"){
      setShowReserv(false);
      setSelectedSimpleOption(null);
    }
  }

  const selectedDateStr = moment(selectedDate).format("YYYY-MM-DD");
  const filteredSchedules = schedules.filter(schedule => schedule.date === selectedDateStr);

  const makeReservation = () => {
    let password_temp
    if(!user){
      alert("사용자를 선택하세요.")
      return
    }
    if(!password){
      password_temp = "1234";
    }else{
      password_temp = password;
    }
    const startDate_day = moment(startDate).format("YYYY-MM-DD"); 
    const endDate_day = moment(endDate).format("YYYY-MM-DD"); 
    let temp_date = moment(startDate);

    while (temp_date.format("YYYY-MM-DD") <= endDate_day) {
      let startTime, endTime, date;

      if (temp_date.format("YYYY-MM-DD") === startDate_day) {
        startTime = moment(startDate).format("HH:mm");
      } else {
        startTime = "00:00";
      }

      if (temp_date.format("YYYY-MM-DD") === endDate_day) {
        endTime = moment(endDate).format("HH:mm");
      } else {
        endTime = "23:59";
      }

      date = moment(temp_date).format("YYYY-MM-DD");

      const temp_schedule = {"date":date, "user":user, "startTime":startTime, "endTime":endTime, "memo":memo, "password":password_temp}

      addScheduleAndReload(temp_schedule);
      
      temp_date.add(1, 'day');
    }
    alert("예약이 완료되었습니다.")
    loadSchedule();

  };

  const handleChangeMemo = (e) => {
    const newMemo = e.target.value;
    setMemo(newMemo);
  }

  const handleChange = (e) => {
    const newPW = e.target.value;
    setPassword(newPW);
  };
  
  return (
    <>
      {showReserve?
        <div className="reserve-wrap" onClick={closeBox}>
          <div className="reserve-box">
            <div className="head-line flexbtw">
              <div className="reserve-title">
                {moment(selectedDate).format("YYYY-MM-DD")} 예약 현황
              </div>
              <div className='close'>닫기</div>
            </div>
            <div className="reserve-list">
              {filteredSchedules.length > 0 ? (
                filteredSchedules.map((schedule, index) => (
                  <Revervation loadSchedule={loadSchedule} removeScheduleAndReload={removeScheduleAndReload} schedule={schedule} key={index}/>
                ))
              ) : (
                <div>해당 날짜에 예약된 스케줄이 없습니다.</div>
              )}
            </div>
            <div className="new-resv-title">새로운 예약</div>
            <div className="selectMode">
              <div className={reserveMode=="simple"?"select-option active":"select-option"} onClick={()=>{setReserveMode("simple"); initDates(selectedDate);}}>간편예약</div>
              <div className={reserveMode=="detail"?"select-option active":"select-option"} onClick={()=>{setReserveMode("detail"); setSelectedSimpleOption(null)}}>상세예약</div>
            </div>
            {reserveMode == "simple"? 
            <>
            <div className="reserve-date">예약시간: {moment(selectedDate).format("YYYY-MM-DD")} {moment(startDate).format("HH:mm")}~{moment(startDate).format("MM-DD")!==moment(endDate).format("MM-DD")?"익일":""}{moment(endDate).format("HH:mm")}</div>
            <div className="reserve-options">
              {simpleReserveOption.map((option, index) => (
              <div className={option==selectedSimpleOption?"reserve-option active":"reserve-option"} key={index} onClick={()=>{setSelectedSimpleOption(option)}}>{option}</div>
                ))
              }
            </div>
            </>:
            <>
            <div className="select-start-date flexbtw">
              <label>시작 날짜&시간</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                dateFormat="yyyy-MM-dd  HH:mm"
                onFocus={(e) => e.target.blur()}
              />
            </div>
            <div className="select-end-date mrtop8 flexbtw">
              <label>종료 날짜&시간</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
                dateFormat="yyyy-MM-dd  HH:mm"
                onFocus={(e) => e.target.blur()}
              />
            </div>
            </>
            }
            <div className="select-user mrtop8 flexbtw">
              <label>사용자 선택</label>
              <select
                value={user}
                onChange={(e) => setUser(e.target.value)}
              >
                <option value="">사용자를 선택해주세요</option>
                {userList.map((username, index) => (
                  <option key={index} value={username}>{username}</option>
                ))}
              </select>
            </div>
            <div className="memo-wrap mrtop8 flexbtw">
              <label>메모</label>
              <input className="password" value={memo} placeholder='메모를 입력해주세요.' onChange={handleChangeMemo}></input>
            </div>
            <div className="pw-wrap mrtop8 flexbtw">
              <label>비밀번호</label>
              <input className="password" value={password} placeholder='비밀번호를 입력해주세요.' onChange={handleChange}></input>
            </div>
            <div onClick={makeReservation} className="reserve-button">예약하기</div>
          </div>
        </div>
        :<></>
      }
      <div className="App">
        <div className="header">
          <img id="logo" src="Lincoln_logo.png" alt="Lincoln_logo" />
          <div className="title">은기네 코세어 예약하기</div>
        </div>
        <div className="main">
          <div className="noti">은기네 코세어 예약 페이지입니다.</div>
          <div className="noti">달력을 누르시면 예약 정보 확인과 신규 예약이 가능합니다.</div>
          
          <div className="calendar">
            <div className="decs">예약 현황</div>
            <div className="color-info">
              {userList.map((username, index) => (
                <div key={index} className="chunk">
                  <div className={"dot "+username}></div>
                  <div className="user-name">{username}</div>
                </div>
              ))}
            </div>
            <Calendar
              onChange={onClickCalendar}
              formatDay={(locale, date) => moment(date).format("DD")}
              value={value}
              className="mx-auto w-full text-sm border-b"
              tileContent={({ date, view }) => {
              let dots = [];
              const dateStr = moment(date).format("YYYY-MM-DD");
    
              const filteredSchedules = schedules.filter((x) => x.date === dateStr);
    
              filteredSchedules.forEach((schedule, index) => {
                dots.push(<div key={index} className={"dot "+schedule.user}></div>);
              });
    
              return (
                <div className="dot-container">
                  {dots}
                </div>
              );
            }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
