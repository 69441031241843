
export const GetSchedules = async () => {
    try {
        const response = await fetch('https://api.inbodychat.kr/getschedules', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch response from API');
        }

        const data = await response.json();
        return data.schedules

    } catch (error) {
        console.error('Error:', error);
        return []
    }
}


export const AddSchedule = async (schedule) => {
    const { user, date, startTime, endTime, memo, password } = schedule;

    const requestBody = {
        user,
        date,
        startTime,
        endTime,
        memo,
        password
    };

    try {
        const response = await fetch('https://api.inbodychat.kr/addschedule', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            throw new Error('Failed to fetch response from API');
        }

        if (!response.ok) {
            throw new Error('Failed to fetch response from API');
        }

        return true

    } catch (error) {
        console.error('Error:', error);
        return false
    }
}


export const RemoveSchedule = async (id, pw) => {
    const requestBody = {
        id: id,
        password: pw
    };

    try {
        const response = await fetch('https://api.inbodychat.kr/removeschedule', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            throw new Error('Failed to fetch response from API');
        }

        return true

    } catch (error) {
        console.error('Error:', error);
        return false
    }
}